import React, { useState, useEffect } from 'react';
import { DynamoDB } from 'aws-sdk';
import { useAuthenticator } from '@aws-amplify/ui-react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const CaseList = ({ getCaseList }) => {

    const dynamoDB = new DynamoDB.DocumentClient({
        region: process.env.REACT_APP_AWS_REGION,
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    });

    const { user } = useAuthenticator((context) => [context.user]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [cases, setCases] = useState([]);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [caseToDelete, setCaseToDelete] = useState(null);

    const hostname = window.location.hostname.split(".")[0]; // Set the network ID dynamically
    const networkId = hostname;
    console.log("hostname", hostname);

    const openDeleteDialogue = (matter) => {
        setCaseToDelete(matter);
        setDeleteDialogOpen(true);
    };

    const closeDeleteDialogue = () => {
        setDeleteDialogOpen(false);
        setCaseToDelete(null);
    };

    const handleDelete = async () => {
        if (caseToDelete) {
            try {
                await deleteCase(caseToDelete);  // Perform the delete operation

                // Remove the deleted case from the state to update the UI
                setCases(cases.filter(matter => matter.id !== caseToDelete));

                // Close the dialog after successful deletion
                closeDeleteDialogue();
            } catch (err) {
                console.error("Failed to delete case:", err);
            }
        }
    };

    const deleteCase = async (caseId) => {
        try {
            // First, get the current case logs for this network
            const queryParams = {
                TableName: 'networks',
                Key: {
                    id: networkId  // Assuming networkId is available in the component
                },
                ProjectionExpression: 'case_logs'
            };
    
            const result = await dynamoDB.get(queryParams).promise();
            const caseLogs = result.Item.case_logs || [];
    
            // Find the index of the case to delete
            const indexToDelete = caseLogs.findIndex(matter => matter.id === caseId);
            if (indexToDelete === -1) {
                throw new Error(`Case with ID ${caseId} not found`);
            }
    
            // Now remove the case from the array using an UpdateExpression
            const updateParams = {
                TableName: 'networks',
                Key: {
                    id: networkId  // Same networkId
                },
                UpdateExpression: `REMOVE case_logs[${indexToDelete}]`,
                ReturnValues: 'UPDATED_NEW'
            };
    
            await dynamoDB.update(updateParams).promise();
            console.log(`Case with ID ${caseId} deleted successfully`);
        } catch (err) {
            console.error(`Failed to delete case with ID ${caseId}:`, err.message);
            throw err;
        }
    };
    

    useEffect(() => {
        const fetchCases = async () => {
            try {
                const queryParams = {
                    TableName: 'networks',
                    KeyConditionExpression: '#id = :networkId',
                    ExpressionAttributeNames: {
                        '#id': 'id',
                    },
                    ExpressionAttributeValues: {
                        ':networkId': hostname,
                    },
                    ProjectionExpression: 'case_logs',
                };

                const result = await dynamoDB.query(queryParams).promise();

                if (!result.Items || result.Items.length === 0) {
                    setError('No case logs found for this network');
                } else {
                    const caseLogs = result.Items[0].case_logs;
                    setCases(caseLogs);
                    console.log('Cases:', caseLogs);
                }
            } catch (err) {
                setError(`Error fetching case logs: ${err.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchCases();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', display: 'flex', flexDirection: 'column', gap: '12px' }}>
                <h3>Cases</h3>
                {cases.map((matter, i) => (
                    <ListItem key={`case-${i}`} alignItems="flex-start" onClick={() => getCaseList(matter)} style={{ cursor: 'pointer', backgroundColor: '#eee' }}
                        secondaryAction={
                            <IconButton edge="end" aria-label="delete">
                                <DeleteIcon onClick={(event) => {
                                    event.stopPropagation();
                                    openDeleteDialogue(matter.id);
                                }} />
                            </IconButton>
                        }
                    >
                        <ListItemText style={{ color: '#000' }}
                            primary={matter.matter_name}
                            secondary={
                                <React.Fragment>
                                    <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="#000">
                                        {matter.matter_number}
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                        <Divider />
                    </ListItem>
                ))}
            </List>
            <Dialog
                open={deleteDialogOpen}
                onClose={closeDeleteDialogue}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">
                    {"Confirm Delete"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        Are you sure you want to delete this case?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialogue} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CaseList;
