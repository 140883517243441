import React, { useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CaseNewForm from './CaseNewForm';
import CaseEditForm from './CaseEditForm';
import CaseList from './CaseList';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CaseManager = (props) => {
  const { children, value, index, ...other } = props;


  const [valueTab, setValueTab] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const [caseData, setCaseData] = useState(null);

  const getCaseList = (caseList) => {
    setCaseData(caseList);
    console.log("Case List on Case Manager", caseList); 
  }

  console.log("CaseData", caseData);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={valueTab} onChange={handleChangeTab} aria-label="basic tabs example">
          <Tab label="Edit Cases" {...a11yProps(0)} />
          <Tab label="Add New Case" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={valueTab} index={0}>
        <div style={{display: 'flex', gap: '12px'}}>
          <CaseList getCaseList={getCaseList}/>
          <CaseEditForm caseData={caseData} />
          {/* <ChannelEditForm channelData={channelData}/> */}
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={valueTab} index={1}>
        <CaseNewForm/>
      </CustomTabPanel>
    </Box>
  );
};

export default CaseManager;
