import React, { useState } from 'react';
import { TextField, Button, Box, Grid, Typography } from '@mui/material';
import { DynamoDB } from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid'; // ES module syntax for uuid

const dynamoDB = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

const CaseNewForm = () => {
    var hostname = window.location.hostname;
  hostname = hostname.split(".");
  hostname = hostname[0];

  const networkId = hostname;
  const [formData, setFormData] = useState({
    alert_1_recipients: '',
    alert_2_recipients: '',
    alert_3_recipients: '',
    billing_attorney: '',
    billing_type: '',
    collaborators: '',
    date_of_loss: '',
    district_court: '',
    docket_number: '',
    first_chair: '',
    leeds_client_id: '',
    leeds_client_matter_id: '',
    matter_name: '',
    matter_number: '',
    matter_status: '',
    open_date: '',
    practice_area: '',
    primary_client: '',
    responsible_attorney: '',
    status: '',
    trial_date: '',
  });


  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const caseLogEntry = {
      ...formData,
      alert_1_recipients: [formData.alert_1_recipients],
      alert_2_recipients: [formData.alert_2_recipients],
      alert_3_recipients: [formData.alert_3_recipients],
      id: uuidv4(), // Generate a unique ID for the category
    };

    // Prepare parameters for the DynamoDB update
    const params = {
      TableName: 'networks', // Table name
      Key: {
        id: networkId, // The id of the network (e.g., "thewillislawgroup")
      },
      UpdateExpression: 'SET case_logs = list_append(if_not_exists(case_logs, :empty_list), :newLog)',
      ExpressionAttributeValues: {
        ':newLog': [caseLogEntry], // Adding the new log entry to the case_logs list
        ':empty_list': [] // Ensure that case_logs exists, create an empty list if it doesn't
      },
      ReturnValues: 'UPDATED_NEW'
    };

    try {
      // Update the DynamoDB item
      const result = await dynamoDB.update(params).promise();
      console.log('Form data successfully submitted to DynamoDB:', result);
    } catch (error) {
      console.error('Error submitting data to DynamoDB:', error);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography variant="h4" gutterBottom>
        Case Logs Form
      </Typography>
      <Grid container spacing={2} style={{backgroundColor: '#fff'}}>
        {Object.keys(formData).map((key) => (
          <Grid item xs={12} sm={6} key={key}>
            <TextField
              fullWidth
              label={key.replace(/_/g, ' ').toUpperCase()}
              name={key}
              value={formData[key]}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
        ))}
      </Grid>
      <Box container mt={2} spacing={2} style={{backgroundColor: '#fff'}}>
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default CaseNewForm;