import React, { useEffect, useState, useRef } from 'react';
import { DynamoDB } from 'aws-sdk';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';

// MUI
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import MediaPlayer from './player/MediaPlayer';
import { Link, TextField } from '@mui/material';
import { createTheme, alpha, getContrastRatio } from '@mui/material/styles';
import './broadcast.scss';
import Banner from '../banners/Banners';
import ChannelCheckoutButton from '../stripe/ChannelPurchase';
const violetBase = '#fff';
const violetMain = alpha(violetBase, 0.7);

const theme = createTheme({
  palette: {
    violet: {
      main: violetMain,
      light: alpha(violetBase, 0.5),
      dark: alpha(violetBase, 0.9),
      contrastText: getContrastRatio(violetMain, '#fff') > 4.5 ? '#fff' : '#111',
    },
  },
});

const dynamoDB = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

const Broadcast = () => {
    const { user } = useAuthenticator((context) => [context.user]);
    const [channels, setChannels] = useState([]);
    const [filteredChannels, setFilteredChannels] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('All Items');
    const [categories, setCategories] = useState(['All Items']);
    const [channel, setChannel] = useState(null);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showDetails, setShowDetails] = useState(false);

    const playerRef = useRef(null);
    const setPlayer = (player) => {
        playerRef.current = player;
    };

    useEffect(() => {
        const fetchChannels = async () => {
            try {
                const scanParams = {
                    TableName: 'channels',
                };

                const result = await dynamoDB.scan(scanParams).promise();
                if (result.Items.length === 0) {
                    setError('No items found');
                } else {
                    setChannels(result.Items);
                    setFilteredChannels(result.Items);

                    // Extract unique categories from channels
                    const allCategories = result.Items.flatMap(item => {
                        if (Array.isArray(item.category)) {
                            return item.category.map(cat => cat.title); // Assume 'title' is the relevant field
                        }
                        return [item.category.title];
                    });

                    const uniqueCategories = [...new Set(allCategories)];
                    setCategories(['All Items', ...uniqueCategories]);
                }
            } catch (err) {
                setError(`Error fetching channels: ${err.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchChannels();
    }, []);

    useEffect(() => {
        const filtered = channels.filter(channel => {
            const matchesCategory =
                selectedCategory === 'All Items' ||
                (Array.isArray(channel.category) &&
                    channel.category.some(cat => cat.title === selectedCategory));
            return matchesCategory;
        });
        setFilteredChannels(filtered);
    }, [selectedCategory, channels]);

    const handleOpenTile = item => {
        setOpen(true);
        setChannel(item);
        console.log("Fire item", item);
    };

    const handleClose = () => {
        setOpen(false);
        setChannel(null);
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div style={{backgroundColor:'#000', color: '#fff'}}>
            <h1 className="noTopMargin">Broadcast</h1>
            <Banner purpose="dashboard"/>
            
            {/* <Box sx={{ marginBottom: 2}}>
                <Autocomplete
                    options={categories}
                    value={selectedCategory}
                    onChange={(event, newValue) =>
                        setSelectedCategory(newValue)
                    }
                    renderInput={params => (
                        <TextField
                            {...params}
                            label="Category"
                            variant="outlined"
                            fullWidth
                        />
                    )}
                    sx={{ marginTop: 2 }}
                />
            </Box> */}
            <Grid container wrap="nowrap">
                <Splide
                    options={{
                        rewind: true,
                        width: 'auto',
                        gap: '12px',
                        type: 'slide',
                        perPage: 4,
                    }}
                    style={{ width: '100%' }}
                >
                    {filteredChannels.map((item, index) => (
                        <SplideSlide
                            key={index}
                            onClick={() => handleOpenTile(item)}
                            style={{
                                cursor: 'pointer',
                                backgroundColor: 'rgba(255,255,255,0)',
                                minWidth: '320px',
                                border: '1px solid rgba(255, 255, 255, 0)'
                            }}
                        >
                            <Box sx={{ minWidth: '240px', my: 0, marginTop: '0', [`&:hover #detailsBox-${index}`]: {
                                visibility: 'visible',
                                backgroundColor: '#eee'
                                },
                            }}>
                            {item.channel_image ? (
                                // <img
                                //     style={{
                                //         width: '240px',    // Set the desired width
                                //         minWidth: '240px',
                                //         height: '200px',   // Set the desired height
                                //         objectFit: 'cover', // Crop the image to fit the box
                                //         borderRadius: '12px'
                                //     }}
                                //     alt={item.title}
                                //     src={item.channel_image[0]}
                                // />
                                <div style={{backgroundImage: `url(${item.channel_image[0]})`, backgroundSize: 'cover', backgroundPosition: 'center center', width: '320px', height: '200px', display: 'flex'}}>

                                </div>
                            ) : (
                                <Skeleton
                                    variant="rectangular"
                                    width="320px"
                                    height="240px"
                                />
                            )}

                            <Box className="detailsBox" id={`detailsBox-${index}`} sx={{ pr: 2, padding: '6px', minWidth: '240px' }}>
                                <Typography gutterBottom variant="body2" color="text.primary">
                                    {item.title}
                                </Typography>
                                <Typography
                                    display="block"
                                    variant="caption"
                                    color="text.primary"
                                    dangerouslySetInnerHTML={{ __html: item.description }}
                                />
                                {item.price && (
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <Typography
                                            variant="caption"
                                            color="text.secondary"
                                        >
                                            {`$${item.price} per month`}
                                        </Typography>
                                        <ChannelCheckoutButton channelId={item.id} frontend_url="https://undergroundexpressions.shelcaster.com"/>
                                    </div>
                                )}
                            </Box>
                        </Box>
                        </SplideSlide>
                    ))}
                </Splide>
                <Backdrop
                    sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <CloseIcon
                        onClick={handleClose}
                        style={{
                            position: 'absolute',
                            top: '24px',
                            right: '24px',
                            cursor: 'pointer',
                        }}
                    />
                    {channel && <MediaPlayer setPlayer={setPlayer} channel={channel} programDefaultBackground={null} />}
                </Backdrop>
            </Grid>
        </div>
    );
};

export default Broadcast;
