import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { Button, Typography } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney' ;

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_TEST_KEY);

const ChannelCheckoutButton = ({ channelId, frontend_url }) => {
    const [loading, setLoading] = useState(false);

    const handleCheckout = async (e) => {
        e.stopPropagation();
        setLoading(true);
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_CHANNEL_PURCHASE_API_URL}`, {
                channelId,  // pass channelId to your backend
                frontend_url,
            });

            const stripe = await stripePromise;
            await stripe.redirectToCheckout({ sessionId: data.sessionId });
        } catch (error) {
            console.error("Error creating checkout session", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Button onClick={handleCheckout}>{loading ? `Processing...` : <><AttachMoneyIcon style={{color: '#000'}}/> Subscribe</>}</Button>
        </>
    );
};

export default ChannelCheckoutButton;