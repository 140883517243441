import { useState, useEffect } from 'react';
import AWS from 'aws-sdk'; // Import AWS SDK

const useChannelCategories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Configure AWS SDK globally
    AWS.config.update({
      region: process.env.REACT_APP_AWS_REGION,
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    });

    const fetchCategories = async () => {
      const dynamoDB = new AWS.DynamoDB.DocumentClient();
      const params = {
        TableName: 'networks', // Your DynamoDB table name
        ProjectionExpression: 'channel_categories', // Only fetch the channel_categories attribute
      };

      try {
        const data = await dynamoDB.scan(params).promise();

        // Extract and aggregate the channel_categories from each network
        const allCategories = data.Items.reduce((acc, item) => {
          if (item.channel_categories) {
            return acc.concat(item.channel_categories);
          }
          return acc;
        }, []);

        setCategories(allCategories || []); // Set the aggregated categories
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  return { categories, loading, error };
};

export default useChannelCategories;