import React, { useState, useEffect } from 'react';
import { DynamoDB } from 'aws-sdk';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CaseDetail from './CaseDetail';
import CloseIcon from '@mui/icons-material/Close';

const dynamoDB = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

const Cases = () => {
    var hostname = window.location.hostname;
    hostname = hostname.split(".");
    hostname = hostname[0];

    const networkId = hostname;
    const { user } = useAuthenticator((context) => [context.user]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [cases, setCases] = useState([]);
    const [caseDetail, setCaseDetail] = useState(null);
    const [open, setOpen] = useState(false);

    const columns = [
        { field: 'matter_status', headerName: 'Status', width: 90 },
        {
          field: 'matter_name',
          headerName: 'Case Style',
          width: 150,
          editable: true,
        },
        {
          field: 'primary_client',
          headerName: 'Client',
          width: 110,
          editable: true,
        },
        {
          field: 'matter_number',
          headerName: 'Matter #',
          width: 160,
        },
        {
            field: 'first_chair',
            headerName: '1st Chair',
            width: 160,
          },
          {
            field: 'second_chair',
            headerName: '2nd Chair',
            width: 160,
          },
          {
            field: 'third_chair',
            headerName: '3rd Chair',
            width: 160,
          },
          {
          field: 'open_date',
          headerName: 'Open Date',
          width: 160,
        },
      ];

      useEffect(() => {
        const fetchCases = async () => {
            try {
                const queryParams = {
                    TableName: 'networks',
                    KeyConditionExpression: '#id = :networkId', // Querying for the id field
                    ExpressionAttributeNames: {
                        '#id': 'id', // Attribute name for id
                    },
                    ExpressionAttributeValues: {
                        ':networkId': networkId, // The network id you are querying
                    },
                    ProjectionExpression: 'case_logs', // Only fetch case_logs
                };

                const result = await dynamoDB.query(queryParams).promise();

                if (!result.Items || result.Items.length === 0) {
                    setError('No case logs found for this network');
                } else {
                    const caseLogs = result.Items[0].case_logs;
                    setCases(caseLogs);
                    console.log('Cases:', caseLogs);
                }
            } catch (err) {
                setError(`Error fetching case logs: ${err.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchCases();
    }, []);

    const handleSelectCase = (details) => {
        console.log("case details", details);
        setOpen(true);
        setCaseDetail(details);
    }

    const handleClose = () => {
        setOpen(false);
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }
    return(
        <>
            <h1>Cases</h1>
            <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={cases}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5]}
                    checkboxSelection={false}
                    disableRowSelectionOnClick={false}
                    onRowClick={handleSelectCase}
                    style={{backgroundColor: '#80a1c9', cursor: 'pointer'}}
                />
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                caseDetail={caseDetail}
                >
                <CloseIcon style={{position: 'absolute', top: '24px', right: '24px', cursor: 'pointer'}} onClick={handleClose} />
                {caseDetail ? <CaseDetail caseDetail={caseDetail.row}/> : <CircularProgress color="inherit" /> } 
            </Backdrop>
        </>
    )
}
export default Cases;