import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DynamoDB } from 'aws-sdk';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import ChannelForm from '../ChannelForm';
import ProgramForm from '../ProgramManager';
import CategoryForms from '../CategoryManager';
import DragNDrop from '../../storage/DragNDrop';
import UploadForm from '../../storage/UploadForm';
import EventManager from '../EventManager';
import ChannelManager from '../ChannelManager';
import ProfileNetworkForm from '../ProfileNetworkForm';
import CaseNewForm from './CaseNewForm';
import CaseManager from './CaseManager';
import ProfileNetworkLegalForm from './ProfileNetworkLegalForm';

const dynamoDB = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    
    

    
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

const MediaManagerLegal = () => {
    const networkId = localStorage.getItem("networkId");
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const { userId: routeUserId } = useParams();
    const { user } = useAuthenticator((context) => [context.user]);
    // console.log("User", user);
    // const {userRole} = user.user_role;
    // console.log("User Role", user);
    const [userId, setUserId] = useState(routeUserId || user.userId);
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [disableTab, setDisableTab] = useState(true);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const queryParams = {
                    TableName: 'networks',
                    KeyConditionExpression: 'id = :id',
                    ExpressionAttributeValues: {
                        ':id': networkId,
                    },
                };

                const result = await dynamoDB.query(queryParams).promise();
                if (result.Items.length === 0) {
                    
                    setError('Please create your profile.');
                    setDisableTab(true);
                } else {
                    const networkData = result.Items[0];
                    const users = networkData.networkUsers || [];
                    const existingUserIndex = users.findIndex(u => u.userId === userId);
                    setProfile(users[existingUserIndex]);
                    setDisableTab(false)
                }
            } catch (err) {
                setError(`Error fetching profile: ${err.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
    }, [userId]);

    const handleUpdateUser = () => {
        setDisableTab(false);
    }
    
    return(
        <>
            <h1>Legal Manager</h1>
            <Box sx={{ maxWidth: { xs: '100%' }, bgcolor: 'background.paper', color: '#000' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label="Profile Manager" />
                    <Tab label="Case Manager" disabled={disableTab} />
                    <Tab label="Document Upload" disabled={disableTab} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <h2>Profile Manager</h2>
                   <ProfileNetworkLegalForm/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <h2>Case Manager</h2>
                    <CaseManager/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <h2>Document Upload</h2>
                    <UploadForm/>
                </TabPanel>
            </Box>
        </>
    )
}

export default MediaManagerLegal;