import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Grid, Typography } from '@mui/material';
import { DynamoDB } from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid'; // ES module syntax for uuid

// Initialize DynamoDB DocumentClient
const dynamoDB = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

const CaseEditForm = ({caseData}) => {
    console.log("Case data in edit form", caseData);
    var hostname = window.location.hostname;
    hostname = hostname.split(".");
    hostname = hostname[0];
    const networkId = hostname; // Set the network ID dynamically

    // Initial state for form data
    const [formData, setFormData] = useState({
        alert_1_recipients: caseData?.alert_1_recipients || '',
        alert_2_recipients: caseData?.alert_2_recipients || '',
        alert_3_recipients: caseData?.alert_3_recipients ||  '',
        billing_attorney: caseData?.billing_attorney ||  '',
        billing_type: caseData?.billing_type ||  '',
        collaborators: caseData?.collaborators ||  '',
        date_of_loss: caseData?.date_of_loss ||  '',
        district_court: caseData?.district_court ||  '',
        docket_number: caseData?.docket_number ||  '',
        first_chair: caseData?.first_chair ||  '',
        leeds_client_id: caseData?.leeds_client_id ||  '',
        leeds_client_matter_id: caseData?.leeds_client_matter_id ||  '',
        matter_name: caseData?.matter_name ||  '',
        matter_number: caseData?.matter_number ||  '',
        matter_status: caseData?.matter_status ||  '',
        open_date: caseData?.open_date ||  '',
        practice_area: caseData?.practice_area ||  '',
        primary_client: caseData?.primary_client ||  '',
        responsible_attorney: caseData?.responsible_attorney ||  '',
        status: caseData?.status ||  '',
        trial_date: caseData?.trial_date ||  '',
    });

    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state

    // Fetch case logs from DynamoDB and prepopulate the form
    useEffect(() => {
        if(caseData) {
            setFormData(caseData);
            setLoading(false);
        }
    }, [caseData]);

    // Handle form field changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // Handle form submission (Update the DynamoDB table)
    const handleSubmit = async (e) => {
        e.preventDefault();

        const caseLogEntry = {
            ...formData,
            alert_1_recipients: [formData.alert_1_recipients],
            alert_2_recipients: [formData.alert_2_recipients],
            alert_3_recipients: [formData.alert_3_recipients],
        };

        try {
            // Step 1: Fetch the network item to retrieve the case logs
            const getParams = {
                TableName: 'networks',
                Key: {
                    id: networkId,
                },
            };

            const networkData = await dynamoDB.get(getParams).promise();

            // Step 2: Find the case log that matches the caseData.id
            const caseLogs = networkData.Item.case_logs;
            const logIndex = caseLogs.findIndex(log => log.id === caseData.id);

            if (logIndex === -1) {
                setError('Case log not found.');
                return;
            }

            // Step 3: Update the matching log
            caseLogs[logIndex] = caseLogEntry;

            // Step 4: Update the DynamoDB item with the modified case logs array
            const updateParams = {
                TableName: 'networks',
                Key: {
                    id: networkId,
                },
                UpdateExpression: 'SET case_logs = :updatedLogs',
                ExpressionAttributeValues: {
                    ':updatedLogs': caseLogs,
                },
                ReturnValues: 'UPDATED_NEW',
            };

            const result = await dynamoDB.update(updateParams).promise();
            setLoading(false);
            console.log('Form data successfully updated in DynamoDB:', result);

        } catch (error) {
            console.error('Error updating data in DynamoDB:', error);
            setError('Error updating data in DynamoDB');
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <Box component="form" onSubmit={handleSubmit}>
            <Typography variant="h4" gutterBottom>
                Edit Case Log
            </Typography>
            <Grid container spacing={2} style={{ backgroundColor: '#fff' }}>
                {Object.keys(formData).map((key) => (
                    <Grid item xs={12} sm={6} key={key}>
                        <TextField
                            fullWidth
                            label={key.replace(/_/g, ' ').toUpperCase()}
                            name={key}
                            value={formData[key]}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </Grid>
                ))}
            </Grid>
            <Box mt={2}>
                <Button type="submit" variant="contained" color="primary">
                    Update
                </Button>
            </Box>
        </Box>
    );
};

export default CaseEditForm;
