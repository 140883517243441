import React, { useState, useEffect } from 'react';
import { TextField, Box, Button, Typography, FormControl, Autocomplete, Modal, Select, InputLabel, MenuItem } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './event-form.scss';
import { DynamoDB } from 'aws-sdk';
import { useAuthenticator } from '@aws-amplify/ui-react';
import UploadForm from '../storage/UploadForm';
import { FileUpload } from '@mui/icons-material';
import { fetchUserAttributes } from '@aws-amplify/auth';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';

import { v4 as uuidv4 } from 'uuid';
import CloseIcon from '@mui/icons-material/Close';

const dynamoDB = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

const EventForm = (props) => {
    const { children, value, index, ...other } = props;
    const { user } = useAuthenticator((context) => [context.user]);
    const [pageLoading, setPageLoading] = useState(true);
    const [pageError, setPageError] = useState(null);
    const [eventImage, setEventImage] = useState(null);
    const [formData, setFormData] = useState({
        event_owner: '',
        event_title: '',
        event_description: '',
        event_image: '',
        venue_name: '',
        venue_address: '',
        venue_address_2: '',
        venue_city: '',
        venue_state: '',
        venue_zip: '',
        venue_country: '',
        start_date: dayjs(),  // Initialize as dayjs object
        end_date: dayjs(),    // Initialize as dayjs object
        invitees: [],
        event_status: ''
    });

    const uniqueId = uuidv4();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'auto',
        height: '90vh'
    };

    //Event Image
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false)

    // Event Image Uploader
    const getEventImageFilePath = (path) => {
        console.log("Event Image File Path", path);
        setEventImage(path);
        setFormData({ ...formData, 'event_image': path });
        // handleClose();
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleStartDateChange = (newValue, fieldName) => {
        console.log("Start Date from change", newValue);
        setFormData({ ...formData, 'start_date': newValue });
        
    };

    const handleEndDateChange = (newValue, fieldName) => {
        setFormData({ ...formData, [fieldName]: newValue });
    };

    const handleMultiSelectChange = (event, value) => {
    setFormData({ ...formData, category: value });
    };

    const handleChannelMultiSelectChange = (event, value) => {
    setFormData({ ...formData, channels: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const params = {
            TableName: 'events',
            Item: {
                id: uniqueId,
                event_owner: user.userId,
                event_title: formData.event_title,
                event_description: formData.event_description,
                event_image: formData.event_image,
                venue_name: formData.venue_name,
                venue_address: formData.venue_address,
                venue_address_2: formData.venue_address_2,
                venue_city: formData.venue_city,
                venue_state: formData.venue_state,
                venue_zip: formData.venue_zip,
                venue_country: formData.venue_country,
                start_date: formData.start_date,
                end_date: formData.end_date,
                invitees: formData.invitees,
                event_status: formData.event_status
            }
        }
        try {
            await dynamoDB.put(params).promise();
            console.log('Data submitted successfully');
            // Optionally, clear the form or provide feedback to the user
            setFormData({
                event_title: '',
                event_description: '',
                event_image: '',
                venue_name: '',
                venue_address: '',
                venue_address_2: '',
                venue_city: '',
                venue_state: '',
                venue_zip: '',
                venue_country: '',
                start_date: formData.start_date, // Ensure this is dayjs object
                end_date: formData.end_date,     // Ensure this is dayjs object
                invitees: [],
                event_status: ''
            });
        } catch (error) {
            console.error('Error submitting data to DynamoDB', error);
        }
    }

    const [valueTab, setValueTab] = React.useState(0);

    const handleQuillChange = (value) => {
        setFormData({ ...formData, event_description: value });
    };

    const [eventData, setEventData] = useState(null);

    const getEventList = (eventList) => {
        setEventData(eventList);
        console.log("Event List on Program Manager", eventList); 
    }

    const [selectedDate, setSelectedDate] = React.useState(dayjs());

    console.log("Event Data", eventData);

    return(
        <Box
            component="form"
            sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%', maxWidth: 600, ma: 'auto', mt: 4}}
            onSubmit={handleSubmit}
        >
            <h3>Add a New Event</h3>
            <TextField label="Title" variant="outlined" name="event_title" value={formData.event_title} onChange={handleChange} fullWidth />
            <Typography variant="h6" gutterBottom>
                Description
            </Typography>
            <ReactQuill
                value={formData.event_description}
                onChange={handleQuillChange}
                className="quill-editor"
            />

            <Button onClick={handleOpen} variant="contained" endIcon={<FileUpload />}>Upload Event Image</Button>
            <ul>
                {eventImage && eventImage.map((image, i) => (
                    <li><img src={image} style={{maxWidth: '80px'}} /></li>
                ))}
            </ul>

            <TextField label="Venue Name" variant="outlined" name="venue_name" value={formData.venue_name} onChange={handleChange} fullWidth />
            <TextField label="Venue Address" variant="outlined" name="venue_address" value={formData.venue_address} onChange={handleChange} fullWidth />
            <TextField label="Venue Address 2" variant="outlined" name="venue_address_2" value={formData.venue_address_2} onChange={handleChange} fullWidth />
            <TextField label="Venue City" variant="outlined" name="venue_city" value={formData.venue_city} onChange={handleChange} fullWidth />
            <TextField label="Venue State" variant="outlined" name="venue_state" value={formData.venue_state} onChange={handleChange} fullWidth />
            <TextField label="Venue Zip Code" variant="outlined" name="venue_zip" value={formData.venue_zip} onChange={handleChange} fullWidth />
            <TextField label="Venue Country" variant="outlined" name="venue_country" value={formData.venue_country} onChange={handleChange} fullWidth />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker']}>
                    <DateTimePicker label="Start Date of Event" value={formData.start_date} onChange={(newValue) => handleStartDateChange(newValue, 'start_date')} renderInput={(params) => <TextField {...params} />} />
                </DemoContainer>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker']}>
                    <DateTimePicker label="End Date of Event" value={formData.end_date} onChange={(newValue) => handleEndDateChange(newValue, 'end_date')} renderInput={(params) => <TextField {...params} />} />
                </DemoContainer>
            </LocalizationProvider>
            <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Event Status</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    name="event_status"
                    id="demo-simple-select"
                    value={formData.event_status}
                    label="Age"
                    onChange={handleChange}
                    >
                    <MenuItem value='Active'>Active</MenuItem>
                    <MenuItem value='Pending'>Pending</MenuItem>
                    <MenuItem value='Cancelled'>Cancelled</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            <Button type="submit" variant="contained" color="primary">Submit</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                
            >
                <Box sx={style}>
                    <CloseIcon
                        onClick={handleClose}
                        style={{ position: 'absolute', top: '24px', right: '24px', cursor: 'pointer' }}
                    />
                    <UploadForm getEventImageFilePath={getEventImageFilePath} handleClose={handleClose} filePurpose="eventImage"/>
                </Box>
            </Modal>

        </Box>
    )
}

export default EventForm;