import React, { useState, useEffect } from 'react';
import { TextField, Box, Button, Typography, FormControl, Autocomplete, Modal, Divider, Alert } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../profile-editor.css';
import { DynamoDB } from 'aws-sdk';
import { useAuthenticator } from '@aws-amplify/ui-react';
import UploadForm from '../../storage/UploadForm';
import { CheckCircleOutline, FileUpload } from '@mui/icons-material';
import { fetchUserAttributes } from '@aws-amplify/auth';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const awsConfig = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  },
};


const dynamoDB = new DynamoDB.DocumentClient({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

const ProfileNetworkLegalForm = () => {
  const { user_role: userRole } = useParams();
    console.log("User params", userRole);
  const [attributes, setAttributes] = useState({});
  // const userAttributes = fetchUserAttributes();
  const [givenName, setGivenName] = useState(null);
  const { user } = useAuthenticator((context) => [context.user]);
  console.log("User", user);
  const [alertOpen, setAlertOpen] = useState(false);
  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        const userAttributes = await fetchUserAttributes(user);
        setAttributes(userAttributes);
        console.log("user attributes", attributes)
        if(!attributes.given_name){
          setGivenName(null);
        } else {
          setGivenName(attributes.given_name);
        }
      } catch (err) {
        console.log("Error fetching user attributes:",err.message);
      } 
    };

    fetchAttributes();
  }, []);

  const handleAlertOpen = () => {
    setAlertOpen(true);
    setTimeout(() => {
      handleAlertClose();
    }, 3000)
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  }
  
  const [profilePic, setProfilePic] = useState(null);
  const [backgroundPic, setBackgroundPic] = useState(null);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    artistBandName: '',
    quote: '',
    bio: '',
    profilePic: '',
    backgroundPic: '',
    photoGallery: '',
    genre: [],
    phone: '',
    mobile: '',
    facebook: '',
    instagram: '',
    linkedin: '',
    tiktok: '',
    website: '',
    address: '',
    city: '',
    zip: '',
    postal_state: '',
    country: '',
    user_role: userRole,
  });
  const [loadingProfile, setLoadingProfile] = useState(true);

  const fetchUserProfile = async () => {
    const networkId = localStorage.getItem("networkId");
  
    // Step 1: Fetch the network data
    const queryParams = {
      TableName: 'networks',
      KeyConditionExpression: 'id = :id',
      ExpressionAttributeValues: {
        ':id': networkId
      },
    };
  
    try {
      const result = await dynamoDB.query(queryParams).promise();
      if (result.Items.length > 0) {
        // Assuming networkUsers is an array in the result item
        const networkData = result.Items[0];
        const users = networkData.networkUsers || [];
  
        // Step 2: Find the user by userId
        const networkUser = users.find(u => u.userId === user.userId);
  
        if (networkUser) {
          setFormData(networkUser);
          console.log("User data", networkUser);
        } else {
          console.error('User not found in network');
        }
      } else {
        console.error('Network profile not found');
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    } finally {
      setLoadingProfile(false);
    }
  };
  
  useEffect(() => {
    fetchUserProfile();
  }, [user.userId]);
  
  

  const handleMultiSelectChange = (event, value) => {
    setFormData({ ...formData, genre: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleQuillChange = (value) => {
    setFormData({ ...formData, bio: value });
  };

  //File Uploader
  //Modal
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    height: '90vh'
  };

  const handleCloseProfilePic = () => setOpenProfilePic(false);
  const handleCloseBackgroundPic = () => setOpenBackgroundPic(false);
  const handleClosePhotoGallery = () => setOpenPhotoGallery(false);

  const [openProfilePic, setOpenProfilePic] = React.useState(false);
  const handleOpenProfilePic = () => setOpenProfilePic(true);

  const [openPhotoGallery, setOpenPhotoGallery] = React.useState(false);
  const handleOpenPhotoGallery = () => setOpenPhotoGallery(true);

  function getProfilePicPath(paths) {
    console.log("Profile Pic", paths);
    setProfilePic(paths);
    setFormData({ ...formData, profilePic: paths });
  }

  function getPhotoGalleryPath(paths) {
    const getImageSize = (path) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          resolve({ src: path, width: img.width, height: img.height, alt: '' });
        };
        img.onerror = (err) => {
          reject(err);
        };
        img.src = path;
      });
    };
  
    // Create an array of promises to get image sizes
    const promises = paths.map((path) => getImageSize(path));
  
    // Resolve all promises and update state
    Promise.all(promises)
      .then((formattedPaths) => {
        setProfilePic(formattedPaths);
        setFormData({ ...formData, photoGallery: formattedPaths });
      })
      .catch((err) => {
        console.error("Error loading one or more images:", err);
      });
  }
  

  const [openBackgroundPic, setOpenBackgroundPic] = React.useState(false);
  const handleOpenBackgroundPic = () => setOpenBackgroundPic(true);

  function getBackgroundPicPath(paths) {
    console.log("Background Pic", paths);
    setBackgroundPic(paths);
    setFormData({ ...formData, backgroundPic: paths });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const userData = {
      userId: user.userId,
      firstName: formData.firstName,
      lastName: formData.lastName,
      artistBandName: formData.artistBandName,
      quote: formData.quote,
      bio: formData.bio,
      profilePic: formData.profilePic,
      backgroundPic: formData.backgroundPic,
      photoGallery: formData.photoGallery,
      genre: formData.genre,
      phone: formData.phone,
      mobile: formData.mobile,
      facebook: formData.facebook,
      instagram: formData.instagram,
      linkedin: formData.linkedin,
      tiktok: formData.tiktok,
      website: formData.website,
      address: formData.address,
      city: formData.city,
      zip: formData.zip,
      postal_state: formData.postal_state,
      country: formData.country,
      user_role: formData.user_role ? formData.user_role : 'fan',
    };
  
    const networkId = localStorage.getItem("networkId");
  
    try {
      // Fetch the current network data
      const queryParams = {
        TableName: 'networks',
        KeyConditionExpression: 'id = :id',
        ExpressionAttributeValues: {
          ':id': networkId
        },
      };
  
      const result = await dynamoDB.query(queryParams).promise();
      if (result.Items.length > 0) {
        const networkData = result.Items[0];
        const users = networkData.networkUsers || [];
  
        // Check if user already exists
        const existingUserIndex = users.findIndex(u => u.userId === user.userId);
  
        if (existingUserIndex > -1) {
          // User exists, update the record
          const updateParams = {
            TableName: 'networks',
            Key: {
              id: networkId,
            },
            UpdateExpression: `SET networkUsers[${existingUserIndex}] = :newUser`,
            ExpressionAttributeValues: {
              ':newUser': userData,
            },
            ReturnValues: 'UPDATED_NEW',
          };
  
          await dynamoDB.update(updateParams).promise();
          handleAlertOpen();
          console.log('User updated successfully');
        } else {
          // User does not exist, add new record
          const updateParams = {
            TableName: 'networks',
            Key: {
              id: networkId,
            },
            UpdateExpression: `SET networkUsers = list_append(if_not_exists(networkUsers, :empty_list), :newUser)`,
            ExpressionAttributeValues: {
              ':newUser': [userData],
              ':empty_list': [],
            },
            ReturnValues: 'UPDATED_NEW',
          };
  
          await dynamoDB.update(updateParams).promise();
          console.log('User added to network successfully');
        }
  
        // Update family_name attribute in Cognito User Pool
        const userAttributes = [
          {
            Name: 'family_name',
            Value: formData.lastName,
          },
        ];
  
        // Optionally update Cognito attributes here
        // await Auth.updateUserAttributes(user, userAttributes);
  
        console.log('Cognito user attribute updated successfully');
      } else {
        console.error('Network profile not found');
      }
    } catch (error) {
      console.error('Error updating network in DynamoDB:', error);
      console.error('Error details:', JSON.stringify(error, null, 2));
    }
  };
  

//   if (loadingProfile || loading) {
//     return <p>Loading profile...</p>;
//   }

//   if (error) {
//     console.error('Error fetching categories:', error);
//     return <p>Error loading categories</p>;
//   }

  return (
    <Box
      component="form"
      sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%', maxWidth: 600, mx: 'auto', mt: 4 }}
      onSubmit={handleSubmit}
    >
      {formData.user_role && <Typography variant="h6" gutterBottom>
          <span className="formLabel">User Role: {formData.user_role}</span>
      </Typography>}
      <TextField
        label="First Name"
        variant="outlined"
        name="firstName"
        value={formData.firstName}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="Last Name"
        variant="outlined"
        name="lastName"
        value={formData.lastName}
        onChange={handleChange}
        fullWidth
      />
      {/* <TextField
        label="Artist/Band Name"
        variant="outlined"
        name="artistBandName"
        value={formData.artistBandName}
        onChange={handleChange}
        fullWidth
      /> */}
      <TextField
        label="Favorite Quote"
        variant="outlined"
        name="quote"
        value={formData.quote}
        onChange={handleChange}
        fullWidth
      />
      <Box>
        <Typography variant="h6" gutterBottom>
          <span className="formLabel" style={{color: '#000'}}>Bio</span>
        </Typography>
        <ReactQuill
          value={formData.bio}
          onChange={handleQuillChange}
          className="quill-editor"
          style={{color: '#000'}}
        />
      </Box>
      <Divider/>
      <Button onClick={handleOpenProfilePic} variant="contained" endIcon={<FileUpload />}>Upload Profile Image</Button>
      {formData.profilePic && <ul> 
          <li><img src={formData.profilePic} style={{maxWidth: '80px'}} /></li>
      </ul>}
      <Divider/>
      <Button onClick={handleOpenBackgroundPic} variant="contained" endIcon={<FileUpload />}>Upload Background Image</Button>
      {formData.backgroundPic && <ul> 
          <li><img src={formData.backgroundPic} style={{maxWidth: '80px'}} /></li>
      </ul>}
      <Divider/>
      {/* <FormControl fullWidth>
        <Autocomplete
          multiple
          options={Array.isArray(categories) ? categories : []}
          getOptionLabel={(option) => option.title}
          value={Array.isArray(formData.genre) ? formData.genre : []}
          onChange={handleMultiSelectChange}
          renderInput={(params) => <TextField {...params} label="Genre" variant="outlined" />}
          fullWidth
        />
      </FormControl> */}
      <Divider/>
      <Button onClick={handleOpenPhotoGallery} variant="contained" endIcon={<FileUpload />}>Upload Photo Gallery Images</Button>
      {formData.photoGallery && <ul> 
          {formData.photoGallery.map((photo, i) => (
            <li key={i}><img src={photo.src} style={{maxWidth: '80px'}} /></li>
            ))}
      </ul>}
      <Divider/>
      <TextField
        label="Phone"
        variant="outlined"
        name="phone"
        value={formData.phone}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="Mobile"
        variant="outlined"
        name="mobile"
        value={formData.mobile}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="Facebook"
        variant="outlined"
        name="facebook"
        value={formData.facebook}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="Instagram"
        variant="outlined"
        name="instagram"
        value={formData.instagram}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="LinkedIn"
        variant="outlined"
        name="linkedin"
        value={formData.linkedin}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="TikTok"
        variant="outlined"
        name="tiktok"
        value={formData.tiktok}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="Website"
        variant="outlined"
        name="website"
        value={formData.website}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="Address"
        variant="outlined"
        name="address"
        value={formData.address}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="City"
        variant="outlined"
        name="city"
        value={formData.city}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="ZIP Code"
        variant="outlined"
        name="zip"
        value={formData.zip}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="State"
        variant="outlined"
        name="postal_state"
        value={formData.postal_state}
        onChange={handleChange}
        fullWidth
      />
      <TextField
        label="Country"
        variant="outlined"
        name="country"
        value={formData.country}
        onChange={handleChange}
        fullWidth
      />
      <Button type="submit" variant="contained" color="primary">
        Submit
      </Button>
      <Modal
        open={openProfilePic}
        onClose={handleCloseProfilePic}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      >
        <Box sx={style}>
            <CloseIcon
                onClick={handleCloseProfilePic}
                style={{ position: 'absolute', top: '24px', right: '24px', cursor: 'pointer' }}
            />
          <UploadForm getProfilePicPath={getProfilePicPath} filePurpose="profilePic"/>
        </Box>
      </Modal>
      <Modal
        open={openBackgroundPic}
        onClose={handleCloseBackgroundPic}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      >
        <Box sx={style}>
            <CloseIcon
                onClick={handleCloseBackgroundPic}
                style={{ position: 'absolute', top: '24px', right: '24px', cursor: 'pointer' }}
            />
            <UploadForm getBackgroundPicPath={getBackgroundPicPath} filePurpose="backgroundPic" userId={user.userId}/>
        </Box>
      </Modal>
      <Modal
        open={openPhotoGallery}
        onClose={handleClosePhotoGallery}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      >
        <Box sx={style}>
            <CloseIcon
                onClick={handleClosePhotoGallery}
                style={{ position: 'absolute', top: '24px', right: '24px', cursor: 'pointer' }}
            />
          <UploadForm getPhotoGalleryPath={getPhotoGalleryPath} filePurpose="photoGallery"/>
        </Box>
      </Modal>
      <Dialog
        open={alertOpen}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
                      You profile has been successfuly created!
            </Alert>
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
    </Box>
  );
};

export default ProfileNetworkLegalForm;
