import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import { DynamoDB } from 'aws-sdk';
// import useChannels from '../../hooks/useChannels';
import useProgramCategories from '../../hooks/useProgramCategories';
import { FileUpload } from '@mui/icons-material';
import UploadForm from '../storage/UploadForm';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { v4 as uuidv4 } from 'uuid';
import usePrograms from '../../hooks/usePrograms';

const dynamoDB = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

const ChannelEditForm = ({ channelData }) => {
    // Ensure channels is an array and has objects with title property
    console.log("channel Data", channelData);
    const { categories, loading, error } = useProgramCategories();
    const {programs, programLoading, programError} = usePrograms();
    const [channelImage, setChannelImage] = useState(null); // Initialize as an empty array

    const [pageLoading, setPageLoading] = useState(true);
    const [pageError, setPageError] = useState(null);
    const [formData, setFormData] = useState({
        title: channelData?.title || '',
        category: channelData?.category || [],
        description: channelData?.description || '',
        channel_image: channelData?.channel_image || null,
        broadcast_type: channelData?.broadcast_type || '',
        premium: channelData?.premium || '',
        price: channelData?.price || '',
        frequency: channelData?.frequency || '',
        programs: channelData?.programs || []
    });
    

    useEffect(() => {
        console.log("channelData in useeffect", channelData);
        if (channelData) {
            setFormData(channelData);
            console.log("channelImage", channelData.channel_image);
            if(channelData && Array.isArray(channelData.channel_image)){
                setChannelImage(
                    channelData.channel_image// ? programData.coverArt.split(',') : [] // Convert string to array
                );
            }
        }
        setPageLoading(false);
    }, [channelData]);

    // Modal styles
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'auto',
        height: '90vh'
    };

    // Handle changes
    const handleMultiSelectChange = (event, value) => {
        setFormData({ ...formData, category: value });
    };

    const handleProgramMultiSelectChange = (event, value) => {
        setFormData((prev) => ({ ...prev, 'programs': value }));
    };
    

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData({ ...formData, [name]: checked });
    };

    const handleQuillChange = (value) => {
        setFormData({ ...formData, description: value });
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // File Uploader
    function getChannelImageFilePath(paths) {
        console.log("Channel Image File paths", paths);
        setChannelImage(paths);
        setFormData({ ...formData, 'channel_image': paths})
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        // Prepare data for DynamoDB
        const dataToSubmit = {
            title: formData.title || '',
            category: formData.category && Array.isArray(formData.category) ? formData.category : [], // Array of objects
            description: formData.description || '',
            channel_image: formData.channel_image || '', // Single string
            broadcast_type: formData.broadcast_type || '',
            premium: formData.premium || '',
            price: formData.price || '', // Single string
            frequency: formData.frequency || '',
            programs: formData.programs && Array.isArray(formData.programs) ? formData.programs : []
        };
    
        console.log('Data to submit:', dataToSubmit);
    
        try {
            // Example function to update DynamoDB
            await updateDynamoDB(dataToSubmit);
            console.log('Update successful');
        } catch (error) {
            console.error('Error updating data in DynamoDB:', error);
            // Show error message to user if needed
        }
    };

    // const transformChannels = (channelsData) => {
    //     console.log("ChannelsData", channelsData);
    //     return channelsData.L.map((channel) => ({
    //       id: channel.M.id.S,
    //       title: channel.M.title?.S || '',  // Assuming title exists directly in channel.M
    //       broadcast_type: channel.M.broadcast_type?.S || '',
    //       category: channel.M.category?.L.map(cat => ({
    //         id: cat.M.id.S,
    //         title: cat.M.title.S,
    //         type: cat.M.type.S
    //       })),
    //       description: channel.M.description?.S || '',
    //       frequency: channel.M.frequency?.S || '',
    //       premium: channel.M.premium?.BOOL || false,
    //       price: channel.M.price?.S || '',
    //       programs: channel.M.programs?.N || 0,
    //     }));
    // };

    // useEffect(() => {
    //     console.log("channels in useEffect", channels);
    //     const channelsFromDB = transformChannels(channels);
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         channels: channelsFromDB
    //     }));
    // }, [channels]);
    
    // Function to update DynamoDB
    const updateDynamoDB = async (data) => {
        const params = {
            TableName: 'channels',
            Key: {
                id: formData.id, // Ensure this matches your partition key
            },
            UpdateExpression: 'set title = :title, category = :category, description = :description, channel_image = :channel_image, broadcast_type = :broadcast_type, premium = :premium, price = :price, frequency = :frequency, programs = :programs',
            // ExpressionAttributeNames: {
            //     '#u': 'url', // Alias for the reserved keyword
            // },
            ExpressionAttributeValues: {
                ':title': data.title,
                ':category': data.category,
                ':description': data.description,
                ':channel_image': data.channel_image,
                ':broadcast_type': data.broadcast_type,
                ':premium': data.premium,
                ':price': data.price,
                ':frequency': data.frequency,
                ':programs': data.programs
            },
            ReturnValues: 'UPDATED_NEW',
        };
    
        console.log('DynamoDB params:', JSON.stringify(params, null, 2));
    
        try {
            const result = await dynamoDB.update(params).promise();
            console.log('DynamoDB update result:', result);
        } catch (error) {
            console.error('DynamoDB update error:', JSON.stringify(error, null, 2));
            throw error;
        }
    };
    
    
    
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    if (pageLoading) {
        return <p>Loading...</p>;  // Or any other loading indicator
    }

    if (pageError) {
        return <p>{pageError}</p>;
    }

    
      

    return (
        <>
            {channelData && <Box
                component="form"
                sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%', maxWidth: 600, mx: 'auto', mt: 4 }}
                onSubmit={handleSubmit}
                >
                    <h3>Update This Channel</h3>
                <TextField label="Title" variant="outlined" name="title" value={formData.title} onChange={handleChange} fullWidth />

                <Autocomplete
                    multiple
                    options={Array.isArray(categories) ? categories : []}
                    getOptionLabel={(option) => option.title || ''}
                    value={formData.category}
                    onChange={handleMultiSelectChange}
                    renderInput={(params) => <TextField {...params} label="Category" variant="outlined" />}
                    fullWidth
                />

                <Box>
                    <Typography variant="h6" gutterBottom>
                    Description
                    </Typography>
                    <ReactQuill
                    value={formData.description}
                    onChange={handleQuillChange}
                    className="quill-editor"
                    />
                </Box>

                <Button onClick={handleOpen} variant="contained" endIcon={<FileUpload />}>Upload Channel Image</Button>
                    <ul>
                        {channelImage && Array.isArray(channelImage) && channelImage.map((image, i) => (
                            <li key={i+Math.random(4)}><img src={image} style={{maxWidth: '80px'}} /></li>
                        ))}
                    </ul>

                <FormControl fullWidth>
                    <Autocomplete
                        multiple
                        options={Array.isArray(programs) ? programs : []}
                        getOptionLabel={(option) => option.title || ''}
                        value={Array.isArray(formData.programs) ? formData.programs : []}
                        onChange={handleProgramMultiSelectChange}
                        renderInput={(params) => <TextField {...params} label="Programs" variant="outlined" />}
                        fullWidth
                    />
                </FormControl>

                <FormControlLabel
                    control={<Checkbox checked={formData.premium} onChange={handleCheckboxChange} name="premium" />}
                    label="Premium"
                />

                {formData.premium && (
                    <>
                    <TextField label="Price" variant="outlined" name="price" value={formData.price} onChange={handleChange} fullWidth />

                    <FormControl fullWidth>
                        <InputLabel id="frequency-label">Frequency</InputLabel>
                        <Select
                        labelId="frequency-label"
                        label="Frequency"
                        name="frequency"
                        value={formData.frequency}
                        onChange={handleChange}
                        >
                        <MenuItem value="Monthly">Monthly</MenuItem>
                        <MenuItem value="Yearly">Yearly</MenuItem>
                        </Select>
                    </FormControl>
                    </>
                )}

                <Button type="submit" variant="contained" color="primary">Submit</Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    
                >
                    <Box sx={style}>
                        <CloseIcon
                            onClick={handleClose}
                            style={{ position: 'absolute', top: '24px', right: '24px', cursor: 'pointer' }}
                        />
                        <UploadForm getChannelImageFilePath={getChannelImageFilePath} filePurpose="channelImage"/>
                    </Box>
                </Modal>
                </Box>}
        </>
    );
    
};

export default ChannelEditForm;