import React, { useState } from 'react';
import { TextField, Box, Button, MenuItem, Select, FormControl, InputLabel, Typography, Modal } from '@mui/material';
import { DynamoDB } from 'aws-sdk';
import CloseIcon from '@mui/icons-material/Close';
import UploadForm from '../storage/UploadForm';
import { FileUpload } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid'; // ES module syntax for uuid

// Configure AWS SDK
const dynamoDB = new DynamoDB.DocumentClient({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});



const CategoryForms = () => {
  var hostname = window.location.hostname;
  hostname = hostname.split(".");
  hostname = hostname[0];

  const networkId = hostname;
  const [programCategoryForm, setProgramCategoryForm] = useState({
    title: '',
    type: ''
  });

  const [channelCategoryForm, setChannelCategoryForm] = useState({
    title: '',
    type: '',
    channel_category_image: ''
  });

  const handleProgramCategoryChange = (e) => {
    const { name, value } = e.target;
    setProgramCategoryForm({ ...programCategoryForm, [name]: value });
  };

  const handleChannelCategoryChange = (e) => {
    const { name, value } = e.target;
    setChannelCategoryForm({ ...channelCategoryForm, [name]: value });
  };

  const handleProgramCategorySubmit = (e) => {
    e.preventDefault();
    console.log('Program Category Form data submitted:', programCategoryForm);
    // Add your submit logic here
  };
  const [open, setOpen] = React.useState(false);
  const [channelCategoryImage, setChannelCategoryImage] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    height: '90vh'
  };

  const getChannelCategoryImageFilePath = (paths)=>{
    setChannelCategoryImage(paths);
    setChannelCategoryForm({ ...channelCategoryForm, 'channel_category_image': paths });
  }

  const handleChannelCategorySubmit = async (e) => {
    e.preventDefault();
    console.log('Channel Category Form data submitted:', channelCategoryForm);

    const params = {
      TableName: 'networks', // The table where networks are stored
      Key: { id: networkId }, // The partition key identifying the specific network
      UpdateExpression: 'SET channel_categories = list_append(if_not_exists(channel_categories, :empty_list), :newCategory)',
      ExpressionAttributeValues: {
        ':empty_list': [],
        ':newCategory': [{
          id: uuidv4(), // Generate a unique ID for the category
          title: channelCategoryForm.title,
          type: channelCategoryForm.type,
          channel_category_image: channelCategoryForm.channel_category_image // Optional: include the image if needed
        }],
      },
      ReturnValues: 'UPDATED_NEW', // Returns the updated attributes
    };
    
    try {
      const result = await dynamoDB.update(params).promise();
      console.log('Category added successfully:', result);
      
      // Optionally, reset the form or provide feedback
      setChannelCategoryForm({
        title: '',
        type: '',
      });
    } catch (error) {
      console.error('Error adding category to network:', error);
    }
};
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, width: '100%', maxWidth: 600, mx: 'auto', mt: 4 }}>
      <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} onSubmit={handleChannelCategorySubmit}>
        <Typography variant="h6">Channel Categories</Typography>
        <TextField label="Title" variant="outlined" name="title" value={channelCategoryForm.title} onChange={handleChannelCategoryChange} fullWidth />
        <FormControl fullWidth>
          <InputLabel id="channel-type-label">Type</InputLabel>
          <Select
            labelId="channel-type-label"
            label="Type"
            name="type"
            value={channelCategoryForm.type}
            onChange={handleChannelCategoryChange}
          >
            <MenuItem value="genre">Genre</MenuItem>
            <MenuItem value="feature">Feature</MenuItem>
          </Select>
        </FormControl>
        <Button onClick={handleOpen} variant="contained" endIcon={<FileUpload />}>Upload Channel Category Image</Button>
          <ul>
              {channelCategoryImage && channelCategoryImage.map((image, i) => (
                  <li key={i+Math.random(4)}><img src={image} style={{maxWidth: '80px'}} /></li>
              ))}
          </ul>
        <Button type="submit" variant="contained" color="primary">Submit</Button>
      </Box>
      <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} onSubmit={handleProgramCategorySubmit}>
        <Typography variant="h6">Program Categories</Typography>
        <TextField label="Title" variant="outlined" name="title" value={programCategoryForm.title} onChange={handleProgramCategoryChange} fullWidth />
        <FormControl fullWidth>
          <InputLabel id="program-type-label">Type</InputLabel>
          <Select
            labelId="program-type-label"
            label="Type"
            name="type"
            value={programCategoryForm.type}
            onChange={handleProgramCategoryChange}
          >
            <MenuItem value="genre">Genre</MenuItem>
            <MenuItem value="feature">Feature</MenuItem>
          </Select>
        </FormControl>
        <Button type="submit" variant="contained" color="primary">Submit</Button>
      </Box>
      <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          
      >
          <Box sx={style}>
              <CloseIcon
                  onClick={handleClose}
                  style={{ position: 'absolute', top: '24px', right: '24px', cursor: 'pointer' }}
              />
              <UploadForm getChannelCategoryImageFilePath={getChannelCategoryImageFilePath } filePurpose="categories"/>
          </Box>
      </Modal>
    </Box>
  );
};

export default CategoryForms;
